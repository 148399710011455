import axios from 'axios'
import React, { Component } from 'react'


class PostDetails extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
         remark: '',
         email: '',
         sn: this.props.item.sn,
         ps_id: this.props.item.ps_id,
         verifier: this.props.item.verifier,
         status_id: this.props.item.status_id
      }
    }

    handleChange =(e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit =(e) => {
        e.preventDefault()
        axios.post('https://kp-sec.securityactivation.com.my/api/add-remark', this.state)
        .then(response =>{
            //console.log(response)
        })
        alert("Report submitted! We will get back to you soon.");
    }


  render() {
        const {remark, email, sn, ps_id, verifier, status_id} = this.state;

        
        
    return (
      <div>
          <form onSubmit={this.handleSubmit}>
                <div style={{textAlign: 'center', marginTop: '-17px', marginBottom: '10px'}}>
                    <label>File Report</label>
                </div>

                <div>
                    <label>Email:</label>
                </div>

                <div>
                    <input type='email' name='email' placeholder='example@mail.com' value={email} onChange={this.handleChange} required/>
                </div>

                <div>
                    <label>Desription:</label>
                </div>

                <div>
                    <textarea name='remark' placeholder='when, where, etc.' value={remark} onChange={this.handleChange} required></textarea>
                </div>
                
                <div style={{textAlign: 'center'}}>
                    <button type='submit' value='submit' name='submit' >Submit</button>
                </div>
            </form>
      </div>
    )
  }
}

export default PostDetails