import React from 'react'
import Popup from '../components/Popup/Popup';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import PostDetails from '../action/post-details';

const Failed = ({item}) => {
    const [buttonPopup, setButtonPopup] = useState(false);

        return (
            <div>
        
                <div className="yourcompany">
                    <img src={require("../assets/images/SurgiPlus Logo 2.png")} alt='main-logo'/>
                </div>
        
                <div className="kotak">
        
                    <div className="result">
                        <div>
                            <img src={require("../assets/images/failedX.png")} alt='failed-icon'/>
                        </div>
                        <h1><span>Authentication</span><span style={{color:'rgb(252, 103, 103)'}}> Failed!</span></h1>
                    
                        <div>
                            <h4 style={{marginTop:'10px', color: '#C5C5C5'}}>
                                {item.message}
                            </h4>
                        </div>
                    </div>
        
                </div>
        
                <div  className='complain'>
                    <span>Not scanned by you? <a onClick={() => setButtonPopup(true)}>Report here</a></span>
                    
                    <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
                        <PostDetails item = {item}/>
                    </Popup>
                </div>
        
        
                <div className='power'>powered by SECURITY ACTIVATION</div>
        
            </div>
          )
}

export default Failed