import React from 'react'

const Successful = ({item}) => {
  return (
    <div>

        <div className="yourcompany">
            <img src={require("../assets/images/SurgiPlus Logo 2.png")} alt='main-logo'/>
        </div>

        <div className="kotak">

            <div className="result">
                <div>
                    <img src={require("../assets/images/successX.png")} alt='failed-icon'/>
                </div>
                <h1><span style={{color:'green'}}>Genuine</span><span> Product!</span></h1>
            
                <div>
                    <h4 style={{marginTop:'10px'}}>
                        <span id='product'>{item.product.name}</span><br/>
                        <table>
                            <tbody>
                                <tr>
                                    <td className='table-left'>Serial Number:</td>
                                    <td className='table-right'>{item.sn}</td>
                                </tr>
                                <tr>
                                    <td className='table-left'>Manufacturing Date:</td>
                                    <td className='table-right'>{item.product.manufactured_date}</td>
                                </tr>
                                <tr>
                                    <td className='table-left'>Expiry Date:</td>
                                    <td className='table-right'>{item.product.expiry_date}</td>
                                </tr>
                            </tbody>
                        </table>
                    </h4>
                </div>
            </div>

        </div>

        <div className='power'>powered by SECURITY ACTIVATION</div>

    </div>
  )
}

export default Successful