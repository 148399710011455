import React, { Component } from "react";
import Failed from "../pages/failed";
import Successful from "../pages/successful";


const c = window.location.search.split('=')[1];

class GetDetails extends Component {

    state = {
        courses: []
    }

    constructor(props) {
        super(props)
        this.state = {
            items: [],
            isLoaded: false
        }
    }


    componentDidMount() {
        fetch('https://kp-sec.securityactivation.com.my/api/verify/' + c)
            .then(res => res.json())
            .then(json => {
                this.setState({
                    items: json
                })
            });
        //.catch(err => {console.log(err)});
    }



    render() {
        return (
            <div>
                {this.state.items.status_code ? <Successful item={this.state.items} /> : <Failed item={this.state.items} />}

            </div>
        )
    }
}

export default GetDetails