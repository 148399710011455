import React from 'react'
import './Popup.css'
import {motion} from 'framer-motion'

function Popup(props) {
  return (props.trigger) ? (
    <motion.div 
        initial={{
            opacity: 0
        }} 
        animate={{
            opacity: 1,
            transition: {
                duration: 0.5
            }
        }} 
        className='popup'>

        <motion.div 
            initial={{
                y: 50
            }} 
            animate={{
                y: 0,
                transition: {
                    duration: 0.8
                }
            }}
            className='popup-inner'>

            
                <button className='close-btn' onClick={() => props.setTrigger(false)}>x</button>
                {props.children}
        </motion.div>
    </motion.div>
  ) : "";
}

export default Popup