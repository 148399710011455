import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Successful from './pages/successful';
import Failed from './pages/failed';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/successful' element={<Successful/>}/>
          <Route path='/failed' element={<Failed/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
